<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">任务名称</label>
        <el-input
          style="width: 140px"
          placeholder="请输入任务名称"
          autocomplete="off"
          size="small"
          clearable
          v-model="taskName"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">教育机构</label>
        <el-input
          style="width: 160px"
          placeholder="请输入教育机构"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchTitle"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">摄影师</label>
        <el-input
          style="width: 130px"
          placeholder="请输入摄影师"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchPhotographer"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集类型</label>
        <el-select
          size="small"
          clearable
          v-model="searchCollectType"
          placeholder="全部"
          style="width: 120px"
        >
          <el-option
            v-for="item in CollectTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="condition-item">
        <label class="fn-14">联系人</label>
        <el-input
          style="width: 140px"
          placeholder="请输入联系人"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchContact"
        ></el-input>
      </div>
      <!-- <div class="condition-item">
        <label class="fn-14">票据号</label>
        <el-input
          style="width: 140px"
          placeholder="请输入票据号"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchBillNo"
        ></el-input>
      </div> -->

      <div class="condition-item">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          v-if="searchButton"
        >
          查询
        </el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: auto">
      <el-table
        :data="ticketList"
        v-loading="ticketLoading"
        stripe
        border
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        @sort-change="sortChange"
      >
        <template #empty>
          <p>{{ ticketLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>

        <el-table-column prop="title" label="任务名称" width="280" fixed>
        </el-table-column>
        <el-table-column prop="schoolName" label="教育机构" width="160" align="center">
        </el-table-column>
        <el-table-column prop="schoolCode" label="院校代码" width="120" align="center">
        </el-table-column>
        <el-table-column prop="contact" label="联系人" width="150" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.contact }}</span></template
          >
        </el-table-column>
        <el-table-column prop="contactPhone" label="联系电话" width="150" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.contactPhone }}</span></template
          >
        </el-table-column>
        <el-table-column prop="totalCount" label="采集人数" width="120" align="center">
        </el-table-column>
        <el-table-column prop="shotPrice" label="收费标准" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ Number(scope.row.shotPrice).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="accountsPayable" label="应交款" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ Number(scope.row.accountsPayable).toFixed(2) }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="photographer" label="摄影师" width="120" align="center">
        </el-table-column>
        <el-table-column
          prop="endDate"
          :formatter="columnDateFormat"
          label="采集时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="batchTime"
          :formatter="columnDateFormat"
          label="上传时间"
          width="120"
          align="center"
          sortable="custom"
        >
        </el-table-column>

        <el-table-column
          prop="collectionTime"
          :formatter="columnDateFormat"
          label="交款时间"
          width="120"
          align="center"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="billTime"
          :formatter="columnDateFormat"
          label="财务入账时间"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="batchReceipt"
          label="票据号"
          width="100"
          align="center"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="educationType" label="学历层次" width="150" align="center">
        </el-table-column>
        <el-table-column prop="shotSubject" label="采集对象" width="120" align="center">
          <template slot-scope="scope">
            <span v-show="scope.row.shotSubject == 0">新生</span>
            <span v-show="scope.row.shotSubject == 1">毕业生</span></template
          >
        </el-table-column>
        <el-table-column
          prop="graduationYear"
          label="毕业年份"
          width="160"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="batchName" label="操作" align="center" width="260">
          <template slot-scope="scope">
            <el-button type="text" @click="handleBillNo(scope.row)" v-if="BillNohButton">
              <span
                v-if="
                  scope.row.billStatus == 0 &&
                  scope.row.isCollection == 0 &&
                  scope.row.informationStatus == 1
                "
              >
                填写票据号</span
              >

              <span
                v-if="scope.row.batchReceipt !== null && scope.row.informationStatus == 1"
              >
                修改票据号</span
              >
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div></div>
    <el-dialog
      :title="BillNoTitle"
      :visible.sync="BillNoVisibleview"
      width="400px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <!-- 散拍 -->
      <el-form ref="billForm" label-width="80px" :model="billForm" :rules="billRules">
        <el-form-item label="票据号" name="billNo" prop="billNo">
          <el-input
            v-model="billForm.billNo"
            style="width: 280px"
            type="text"
            placeholder="请输入票据号"
          ></el-input>
        </el-form-item>

        <el-form-item label="交款时间" name="billTime" prop="billTime">
          <el-date-picker
            v-model="billForm.billTime"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="请选择交款时间"
            autocomplete="off"
            style="width: 280px"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button
          type="primary"
          @click="handleConfirm"
          style="margin-left: 20px"
          :loading="ConfirmLoading"
          >保 存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryAllTask, batchReceiptAdd } from "@/api/ticket";
import { dateFormat } from "@/utils/date";
import { getToken } from "@/utils/auth";
import { queryPageButton } from "@/api/permission";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
export default {
  name: "Notrefunded",
  data() {
    const validateBillNo = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入票据号"));
      } else {
        callback();
      }
    };
    const validatebillTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择交款时间"));
      } else {
        callback();
      }
    };
    return {
      // 列表
      ticketList: [],
      ticketLoading: false,
      // 搜索条件
      taskName: "",
      searchTitle: "",
      searchCollectType: null,
      searchContact: "",
      searchPhotographer: "",
      searchBillNo: "",
      CollectTypeList: [
        {
          label: "校拍",
          value: "0",
        },
        {
          label: "散拍",
          value: "1",
        },
      ],

      loading: false,
      currentPage: 1,
      pageSize: 20,
      total: 0,

      // 票据号
      BillNoVisibleview: false,
      ConfirmLoading: false,
      BillNoTitle: "",
      billForm: {},
      billRules: {
        billNo: [
          {
            required: true,
            trigger: "blur",
            validator: validateBillNo,
          },
        ],
        billTime: [
          {
            required: true,
            trigger: "blur",
            validator: validatebillTime,
          },
        ],
      },

      // 排序
      isBatchTime: null,
      isCollectionTime: null,
      isBillNo: null,

      // 权限按钮
      searchButton: false,
      BillNohButton: false,
    };
  },
  created() {
    this.query();
    this.queryButton();
  },
  methods: {
    queryButton() {
      const data = 140;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "BillNohButton") {
            this.BillNohButton = true;
          }
        }
      });
    },
    query() {
      const data = {
        taskName: this.taskName,
        title: this.searchTitle,
        collectType: this.searchCollectType,
        photographer: this.searchPhotographer,
        billNo: this.searchBillNo,
        contact: this.searchContact,
        isBatchTime: this.isBatchTime,
        isCollectionTime: this.isCollectionTime,
        isBillNo: this.isBillNo,
        unPaid: 0,
      };
      this.ticketLoading = true;
      queryAllTask(data, this.currentPage, this.pageSize).then((resp) => {
        this.ticketList = resp.data.content;
        this.total = resp.data.totalElements;
        this.ticketLoading = false;
      });
    },
    // 排序规则
    sortChange(custom) {
      if (custom.prop == "batchTime") {
        if (custom.order == "ascending") {
          this.isBatchTime = "1";
        } else if (custom.order == "descending") {
          this.isBatchTime = "2";
        }
      } else if (custom.prop == "collectionTime") {
        if (custom.order == "ascending") {
          this.isCollectionTime = "1";
        } else if (custom.order == "descending") {
          this.isCollectionTime = "2";
        }
      } else if (custom.prop == "batchReceipt") {
        if (custom.order == "ascending") {
          this.isBillNo = "1";
        } else if (custom.order == "descending") {
          this.isBillNo = "2";
        }
      }
      this.query();
    },
    // 添加票据号
    handleBillNo(data) {
      this.BillNoVisibleview = true;
      this.isCollection = data.isCollection;
      this.batchReceipt = data.batchReceipt;
      this.billForm = {};
      if (data.billStatus == 0) {
        this.billForm.id = data.id;
        this.billForm.status = 0;
        this.billForm.collectType = data.collectType;
        this.BillNoTitle = "填写票据号";
      } else {
        this.BillNoTitle = "修改票据号";
        let JSON = data.batchReceipt;
        JSON.map((item) => {
          this.billForm = {
            billNo: item.billNo,
            billTime: item.billTime,
            id: data.id,
            collectType: data.collectType,
            status: 1,
          };
        });
      }
    },
    // 票据保存
    handleConfirm() {
      this.$refs.billForm.validate((valid) => {
        if (valid) {
          let json = JSON.parse(JSON.stringify(this.billForm));
          let postdata = json;
          // postdata.billNo = json.billNo;
          // postdata.billTime = json.billTime;
          this.ConfirmLoading = true;
          batchReceiptAdd(postdata).then((resp) => {
            if (resp.code == 0) {
              this.$notify.success({
                title: "成功",
                message: resp.message,
              });
            } else {
              this.$notify({
                title: "失败",
                message: resp.message,
                type: "warning",
              });
            }
            this.query();
            this.ConfirmLoading = false;
            this.BillNoVisibleview = false;
          });
        } else {
          this.ConfirmLoading = false;
          this.BillNoVisibleview = false;
          return false;
        }
      });
    },

    // 票据取消
    handleCancel() {
      this.billForm = {};
      this.BillNoVisibleview = false;
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },

    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style></style>
